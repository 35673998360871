.vertical-list {
    margin: 0 3rem;
}

@media screen and (max-width: 640px) {
    .vertical-list {
        margin: 0 2rem;
    }
    .vertical-list-title {
        font-size: 2.5rem;
    }
    .vertical-list-body {
        font-size: 2rem;
    }
}