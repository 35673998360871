:root {
    --main-light-bg-color:#f4f5f7;
    --main-dark-bg-color: #282c34;
    --main-light-font-color: white;
    --main-light-theme-color: #00e091;
    --main-dark-font-color: #282c34;
    --main-dark-theme-color: #1aac5d;
}

html {
  margin: 0;
  padding: 0;
  background-color: var(--main-dark-bg-color);
}

.app {
    text-align: center;
    background-color: var(--main-light-bg-color);
    height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300&family=Noto+Sans+JP&display=swap');

body {
    font-family: "Noto Sans JP", sans-serif;
}


.dark-theme-color {
  color: var(--main-dark-theme-color);
}

.light-theme-color {
  color: var(--main-light-theme-color);
}

.dark-bg-color {
  background-color: var(--main-dark-bg-color);
}

h1{
  font-size: 5rem;
}

.default-font {
  font-size: 2.5rem;
}

.large-font {
  font-size: 3.5rem;
}

a:hover {
  text-decoration: none;
}
a {
  position: relative;
  display: inline-block;
  transition: .3s;
  color: var(--main-light-theme-color);
}
a::before,
a::after {
  position: absolute;
  content: '';
  width: 0;
  height: 1px;
  background-color: var(--main-dark-bg-color);
  transition: .3s;
}
a::before {
  top: 0;
  left: 0;
}
a::after {
  bottom: 0;
  right: 0;
}
a:hover::before,
a:hover::after {
  width: 100%;
}
