.section-body {
    width: 60vw;
    margin: auto;
    margin-top: 5rem;
    text-align: left;
    padding: 0 2rem;
}

.section-image {
    height: 25rem;
    display: block;
    margin: auto;
    border-radius: 100%;
    box-shadow: 0.1rem 0.1rem 2rem;
}

.section-not-first {
    margin-top: 10rem;
}

@media screen and (max-width: 640px) {
    .section-body {
        width: 95vw;
        margin-top: 3rem;
    }
}