.product {
    width: 100%;
    margin-bottom: 7rem;
}

.product-description {
    font-size: 2rem;
}

.product-name {
    border-bottom: solid 0.1rem var(--main-dark-font-color);
}

.product-techs {
    padding: 0;
}

.product-tech {
    font-size: 1.5rem;
    margin: 0.5rem;
}

.product-link {
    font-size: 1.5rem;
    margin: 0;
}

.product-details {
    margin-bottom: 1rem;
}

.product-view {
    width: 100%;
    bottom: 0;
}

.product-image {
    margin: 0;
    width: 100%;
}

.product-video {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

.product-video:before {
    content: "";
    display: block;
    padding-top: 56.25% /*16:9固定*/
}

.youtube-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 640px) {
    .product-tech {
        margin: 0.3rem;
    }
}