* {
    margin: 0;
    padding: 0;
}

html {
  font-size: 10px;
}

@media screen and (max-width: 640px) {
    html {
      font-size: 7px;
    }
}