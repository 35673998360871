.headline {
    --headline-spacing: 0.8rem;
    font-family: "Lato", sans-serif;
}

.headline-body {
    margin: 0;
    display: inline-block;
    font-size: 6rem;
    text-align: center;
    letter-spacing: var(--headline-spacing);
    vertical-align: middle;
    padding: 0 1.5rem;
    border-bottom: 0.2rem solid var(--main-dark-font-color);
}

.headline-fontawesome {
    margin-right: calc(var(--headline-spacing) + 1rem);
    font-size: 4rem;
}

.headline-image {
    width: 0.8em;
    margin: 0;
    margin-right: var(--headline-spacing);
    border-radius: 30%;
    border: var(--main-dark-bg-color) 1px solid;
    vertical-align: middle;
}